import '../styles/globals.css';
import type { AppProps } from 'next/app';
import '@livekit/components-styles';
import '@livekit/components-styles/prefabs';
import { DefaultSeo } from 'next-seo';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <DefaultSeo
        title="Doczo Meet | Book Online Consultation - Demo App"
        titleTemplate="%s"
        defaultTitle="Doczo Meet | Book Online Consultation - Demo App"
        description="Doczo Meet - a demo app for booking online doctor consulting."
        // twitter={{
        //   handle: '@livekitted',
        //   site: '@livekitted',
        //   cardType: 'summary_large_image',
        // }}
        openGraph={{
          url: 'https://livekit.doczo.dev',
          images: [
            {
              // url: 'https://www.doczo.com/images/doczo-logo.jpg',
              url: '/images/doczo-og-image.png',
              width: 2000,
              height: 1000,
              type: 'image/png',
            },
          ],
          site_name: 'Doczo Meet',
        }}
      // additionalMetaTags={[
      //   {
      //     property: 'theme-color',
      //     content: '#070707',
      //   },
      // ]}
      // additionalLinkTags={[
      //   {
      //     rel: 'icon',
      //     href: '/favicon.ico',
      //   },
      //   {
      //     rel: 'apple-touch-icon',
      //     href: '/images/livekit-apple-touch.png',
      //     sizes: '180x180',
      //   },
      //   {
      //     rel: 'mask-icon',
      //     href: '/images/livekit-safari-pinned-tab.svg',
      //     color: '#070707',
      //   },
      // ]}
      />
      <Component {...pageProps} />
      <style jsx global>{`
        :root {
          .lk-room-container {
            background-color: #05294a !important;
          }
          .lk-button {
            background-color: #007aff !important;
          }
        }
      `}</style>
    </>
  );
}
export default MyApp;
